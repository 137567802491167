'use client';

import { Box, styled } from '@mui/material';

export const StyledParentContainer = styled(Box)({
    position: 'relative',
    maxWidth: '1430px',
    display: 'flex',
    justifyContent: 'flex-end',
});

export const StyledFixedContainer = styled(Box)({
    position: 'fixed',
    bottom: 0,
    zIndex: 20000,
});
